import type { ConnectedProjectDto, LanguageDto, ProjectDetailedDto } from "api/types";
import iconEdit05 from "assets/icons/edit-05.svg";
import { Button } from "components/Button/Button";
import { IconButton } from "components/Button/IconButton";
import { ConfirmModal } from "components/ConfirmModal/ConfirmModal";
import { Form } from "components/Form/Form";
import { FormContent } from "components/Form/FormContent";
import { FormField } from "components/Form/FormField";
import { FormInput } from "components/Form/FormInput";
import { FormInputAutocomplete } from "components/Form/FormInputAutocomplete";
import { FormSelect } from "components/Form/FormSelect";
import { FormSwitch } from "components/Form/FormSwitch";
import { Icon } from "components/Icon/Icon";
import { prepareSuggestions } from "components/InputAutocomplete/InputAutocomplete";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { createRequiredStringRule } from "helpers/rules";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { usePermission } from "hooks/usePermission";
import type { TFunction } from "i18next";
import { canManageProjectManagement } from "modules/project/permissions";
import { useEffect, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";

import { CloseProjectModal } from "./CloseProjectModal";
import { SlugModal } from "./SlugModal";

export interface LayoutProps {
  languages: LanguageDto[];
  projects: ConnectedProjectDto[];
  isEditMode: boolean;
  defaultValues: Partial<FormValues>;
  projectState: ProjectDetailedDto["activeState"];
  isSubmitting: boolean;
  onSubmit: (data: FormValues) => void;
  closeProject: (deleteUsers: boolean) => Promise<unknown>;
  isClosingProject: boolean;
}

export function Layout({
  isEditMode,
  defaultValues,
  languages,
  projects,
  projectState,
  isSubmitting,
  onSubmit,
  closeProject,
  isClosingProject,
}: LayoutProps): React.ReactNode {
  const sessionUser = useSessionUser();
  const { t } = useTranslation();
  const formMethods = useForm<FormValues>({ defaultValues });
  const language = useWatch<FormValues, "language">({ control: formMethods.control, name: "language" });
  const cloneProject = useWatch<FormValues, "cloneProject">({ control: formMethods.control, name: "cloneProject" });
  const owner = useWatch<FormValues, "owner">({ control: formMethods.control, name: "owner" });
  const [isSlugModalOpen, slugModalHandlers] = useBool();
  const [isActivateProjectModalOpen, activateProjectModalHandlers] = useBool();
  const [isCloseProjectModalOpened, closeProjectModalHandlers] = useBool();
  const hasPermission = usePermission();

  const allowedCloneProjects = useMemo(
    () => projects.filter((x) => x.projectBaseLanguageId === language.id),
    [projects, language.id],
  );

  const { setValue } = formMethods;
  useEffect(() => {
    if (cloneProject?.id && !allowedCloneProjects.some((x) => x.id === cloneProject.id)) {
      setValue("cloneProject", undefined);
    }
  }, [cloneProject?.id, allowedCloneProjects, setValue]);

  const citySuggestions = useMemo(() => prepareSuggestions(projects.map((x) => x.city)), [projects]);
  const ownerSuggestions = useMemo(() => prepareSuggestions(projects.map((x) => x.owner)), [projects]);
  const maintenanceSuggestions = useMemo(() => {
    const sameOwnerProjects = projects.filter((x) => x.owner === owner);

    return prepareSuggestions(sameOwnerProjects.map((x) => x.maintenance));
  }, [projects, owner]);

  const takenSlugs = useMemo(() => projects.map((x) => x.slug), [projects]);

  const isActivated = useWatch<FormValues, "isActivated">({ control: formMethods.control, name: "isActivated" });

  return (
    <DocumentPaper
      theme="constrained"
      title={isEditMode ? t("page.projects.edit.title") : t("page.projects.new.title")}
    >
      <Form formMethods={formMethods} onSubmit={onSubmit}>
        <FormContent>
          <FormField label={t("page.projects.form.name")} required htmlFor="name">
            <FormInput<FormValues, "name">
              data-testid="project-name-input"
              id="name"
              name="name"
              rules={{
                validate: {
                  required: createRequiredStringRule(t, "page.projects.form.name"),
                },
              }}
              disabled={!hasPermission(canManageProjectManagement)}
            />
          </FormField>
          {isEditMode && (
            <FormField label={t("page.projects.form.slug")} required htmlFor="slug">
              <div className="flex items-center justify-center gap-2">
                <div className="grow">
                  <FormInput id="slug" name="slug" readOnly />
                </div>
                {hasPermission(canManageProjectManagement) && (
                  <IconButton
                    styling="tertiary"
                    title={t("page.projects.form.slug.edit")}
                    onClick={slugModalHandlers.setTrue}
                  >
                    <Icon name={iconEdit05} size={16} />
                  </IconButton>
                )}
              </div>
            </FormField>
          )}
          <FormField label={t("page.projects.form.city")} required htmlFor="city">
            <FormInputAutocomplete<FormValues>
              data-testid="project-city-input"
              id="city"
              name="city"
              suggestions={citySuggestions}
              rules={{
                validate: {
                  required: createRequiredStringRule(t, "page.projects.form.city"),
                },
              }}
              disabled={!hasPermission(canManageProjectManagement)}
            />
          </FormField>
          <FormField label={t("page.projects.form.owner")} required htmlFor="owner">
            <FormInputAutocomplete<FormValues>
              data-testid="project-owner-input"
              id="owner"
              name="owner"
              suggestions={ownerSuggestions}
              rules={{
                validate: {
                  required: createRequiredStringRule(t, "page.projects.form.owner"),
                },
              }}
              disabled={!hasPermission(canManageProjectManagement)}
            />
          </FormField>
          <FormField label={t("page.projects.form.maintenance")} required htmlFor="maintenance">
            <FormInputAutocomplete<FormValues>
              data-testid="project-maintainer-input"
              id="maintenance"
              name="maintenance"
              suggestions={maintenanceSuggestions}
              rules={{
                validate: {
                  required: createRequiredStringRule(t, "page.projects.form.maintenance"),
                },
              }}
              disabled={!hasPermission(canManageProjectManagement)}
            />
          </FormField>
          <FormField label={t("page.projects.form.type")}>
            <FormSelect<FormValues, FormValues["type"]>
              data-testid="project-type-select"
              name="type"
              items={["addressBased", "companyBased"]}
              keySelector={(x) => x}
              renderOption={(x) => (x === "addressBased" ? "address" : "company")}
              disabled={!hasPermission(canManageProjectManagement)}
            />
          </FormField>
          <FormField label={t("page.projects.form.language")} htmlFor="language">
            <FormSelect<FormValues, LanguageDto>
              data-testid="project-language-select"
              id="language"
              name="language"
              keySelector={(x) => x.id}
              items={languages}
              renderOption={(x) => x.description}
              disabled={isEditMode || !hasPermission(canManageProjectManagement)}
            />
          </FormField>

          {!isEditMode && (
            <FormField label={t("page.projects.form.clone")} htmlFor="clone">
              <FormSelect<FormValues, ConnectedProjectDto>
                data-testid="project-clone-select"
                id="clone"
                name="cloneProject"
                emptyItem={t("page.projects.form.clone.placeholder")}
                items={allowedCloneProjects}
                keySelector={(x) => x.id}
                renderOption={(x) => x.name}
              />
            </FormField>
          )}

          {isEditMode && (
            <FormField label={t("page.projects.form.state")}>
              <div className="flex flex-row flex-wrap items-center gap-8">
                {projectState === "closed" ? (
                  <div className="my-2">🔴 {getProjectState(t, projectState)}</div>
                ) : (
                  <div className="flex flex-row items-center gap-2">
                    <FormSwitch<FormValues>
                      name="isActivated"
                      disabled={
                        !sessionUser.isSuperAdmin ||
                        projectState === "active" ||
                        !hasPermission(canManageProjectManagement)
                      }
                    />
                    <span>{getProjectState(t, isActivated ? "active" : "unknown")}</span>
                  </div>
                )}
              </div>
            </FormField>
          )}
        </FormContent>
        {hasPermission(canManageProjectManagement) && (
          <div className="flex flex-wrap items-center justify-between gap-4">
            <Button
              data-testid="project-submit-btn"
              onClick={formMethods.handleSubmit((data) => {
                if (isEditMode && data.isActivated && !defaultValues.isActivated) {
                  activateProjectModalHandlers.setTrue();
                } else {
                  return onSubmit(data);
                }
              })}
              isLoading={isSubmitting}
            >
              {isEditMode ? t("page.projects.edit.submit") : t("page.projects.new.submit")}
            </Button>
            {isEditMode && sessionUser.isSuperAdmin && projectState !== "closed" && (
              <Button styling="danger" onClick={closeProjectModalHandlers.setTrue}>
                {t("page.projects.edit.state.close")}
              </Button>
            )}
          </div>
        )}
      </Form>
      {hasPermission(canManageProjectManagement) && (
        <>
          <SlugModal
            isOpened={isSlugModalOpen}
            onOpenChange={slugModalHandlers.set}
            initialSlug={defaultValues.slug || ""}
            takenSlugs={takenSlugs}
            onSubmit={(slug) => {
              setValue("slug", slug);
              slugModalHandlers.setFalse();
            }}
          />
          <CloseProjectModal
            isOpened={isCloseProjectModalOpened || isClosingProject}
            onOpenChange={closeProjectModalHandlers.set}
            onSubmit={async (deleteUsers) => {
              await closeProject(deleteUsers);
              closeProjectModalHandlers.setFalse();
            }}
            projectName={defaultValues.name!}
            isLoading={isClosingProject}
          />
          <ConfirmModal
            title={t("page.projects.activate-project.modal.title")}
            description={t("page.projects.activate-project.modal.description")}
            renderDescription={() => (
              <Trans i18nKey="page.projects.activate-project.modal.description" components={{ b: <strong /> }} />
            )}
            isLoading={isSubmitting}
            onReject={(e) => {
              e.preventDefault();
              e.stopPropagation();
              activateProjectModalHandlers.setFalse();
            }}
            onOpenChange={activateProjectModalHandlers.set}
            rejectBtnProps={{
              "data-testid": "activate-project-modal-cancel",
            }}
            onResolve={() => onSubmit(formMethods.getValues())}
            resolveBtnProps={{
              text: t("page.projects.activate-project.modal.confirm"),
              "data-testid": "activate-project-modal-confirm",
            }}
            isOpened={isActivateProjectModalOpen}
            shouldCloseOnEsc
          />
        </>
      )}
    </DocumentPaper>
  );
}

function getProjectState(t: TFunction, state?: ProjectDetailedDto["activeState"]) {
  switch (state) {
    case "unknown":
      return t("page.projects.form.state.inactive");
    case "active":
      return t("page.projects.form.state.active");
    case "closed":
      return t("page.projects.form.state.closed");
    default:
      return "???";
  }
}

interface FormValues {
  name: string;
  slug?: string;
  city: string;
  owner: string;
  maintenance: string;
  language: LanguageDto;
  type: ProjectDetailedDto["type"];
  cloneProject?: ConnectedProjectDto;
  isActivated: boolean;
}
