import { useQuery } from "@tanstack/react-query";
import type { AdoptionRateDetailsDto, AdoptionRateDto } from "api/types";
import { Button } from "components/Button/Button";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { InfoIcon } from "components/InfoIcon/InfoIcon";
import { useSlug } from "hooks/useSlug";
import { DonutChart } from "modules/analytics/components/DonutChart";
import { NoData } from "modules/analytics/components/NoData";
import { ChartLegend } from "modules/analytics/components/utility/ChartLegend";
import { dataColors } from "modules/analytics/theme";
import { AnimatePresence, motion } from "motion/react";
import { useAnalyticsQueries } from "queries/analytics/queryOptions";
import type React from "react";
import { useTranslation } from "react-i18next";
import { routes } from "routes";

import { AdoptionWidgetScoreReferenceLabel } from "./AdoptionWidgetScoreReferenceLabel";

const CHART_SIZE = 160;
const FALLBACK_ADOPTION_STATS: AdoptionRateDto = {
  adoptionRatePercentage: 0,
  totalAddresses: 0,
  totalAddressesInvited: 0,
  invitedAddressesActivated: 0,
  invitedAddressesNotActivated: 0,
  unknownStatusAddresses: 0,
  totalResidents: 0,
};
const FALLBACK_BENCHMARK = 0;

export function AdoptionWidget(): React.ReactNode {
  const { t } = useTranslation();
  const slug = useSlug();

  const analyticsQueries = useAnalyticsQueries();

  const {
    data: adoption,
    isPending: isPendingAdoption,
    isError: isErrorAdoption,
    error: errorAdoption,
    refetch: refetchAdoption,
  } = useQuery(analyticsQueries.getAdoption());
  const {
    data: adoptionDetails,
    isPending: isPendingAdoptionDetails,
    isError: isErrorAdoptionDetails,
    error: errorAdoptionDetails,
    refetch: refetchAdoptionDetails,
  } = useQuery(analyticsQueries.getAdoptionDetails("last6Months"));

  const getProjectPeriodLabel = (period: AdoptionRateDetailsDto["period"]) => {
    switch (period) {
      case "zeroToOneMonth":
        return t("component.analytics-widget.adoption.project-period.1.label");
      case "twoToFourMonths":
        return t("component.analytics-widget.adoption.project-period.2.label");
      case "fourToSixMonths":
        return t("component.analytics-widget.adoption.project-period.3.label");
      case "sixToNineMonths":
        return t("component.analytics-widget.adoption.project-period.4.label");
      case "nineToTwelveMonths":
        return t("component.analytics-widget.adoption.project-period.5.label");
      case "twelveToEighteenMonths":
        return t("component.analytics-widget.adoption.project-period.6.label");
      case "eighteenToTwentyFourMonths":
        return t("component.analytics-widget.adoption.project-period.7.label");
      case "twoPlusYears":
        return t("component.analytics-widget.adoption.project-period.8.label");
    }
  };

  const onReload = () => {
    void refetchAdoption();
    void refetchAdoptionDetails();
  };

  const isLoading = isPendingAdoption || isPendingAdoptionDetails;
  const isError = isErrorAdoption || isErrorAdoptionDetails;
  const error = errorAdoption || errorAdoptionDetails;

  const adoptionStats = adoption || FALLBACK_ADOPTION_STATS;
  const benchmarkPercentage =
    adoptionDetails?.benchmark[adoptionDetails?.benchmark.length - 1]?.percentage || FALLBACK_BENCHMARK;

  return (
    <div className="flex h-fit w-full flex-col items-center gap-6 rounded-lg bg-white p-4">
      <section className="flex w-full items-center justify-between">
        <div className="flex items-center justify-center gap-1 self-start">
          <h3 className="text-headline3">{t("component.analytics-widget.adoption.title")}</h3>
          <InfoIcon tooltip={t("component.analytics-widget.fah-score.info.tooltip")} />
        </div>
        <Button type="link" styling="ghostPrimary" href={routes.analytics.adoptionDetails({ slug })}>
          {t("component.analytics-widget.adoption.source.btn")}
        </Button>
      </section>

      <section className="relative flex flex-1 flex-col items-center justify-center gap-6">
        <DonutChart size={CHART_SIZE} value={adoptionStats.adoptionRatePercentage} />
        <ChartLegend
          items={[
            {
              label: t("component.analytics-widget.adoption.chart.legend.primary", {
                count: adoptionStats.invitedAddressesActivated,
              }),
              color: dataColors.primary,
            },
          ]}
        />

        <AdoptionWidgetScoreReferenceLabel
          currValue={adoptionStats.adoptionRatePercentage}
          referenceValue={benchmarkPercentage}
        />

        <div className="flex flex-col">
          <h4 className="text-headline4">
            {t("component.analytics-widget.common.aop-benchmark.label", {
              score: `${benchmarkPercentage.toFixed(0)}%`,
            })}
          </h4>
          <p className="text-body text-grey-700">
            {t("component.analytics-widget.adoption.description", {
              period: getProjectPeriodLabel(adoptionDetails?.period || "zeroToOneMonth"),
            })}
          </p>
        </div>

        <AnimatePresence>
          {isLoading && <LoadingState />}
          {isError && <ErrorState {...{ error, onReload }} />}
        </AnimatePresence>
      </section>
    </div>
  );
}

function LoadingState() {
  return (
    <motion.div
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2, ease: "linear", delay: 0.2 }}
      className="absolute inset-0 z-10 bg-white"
    >
      <FullSizeLoader noDelay />
    </motion.div>
  );
}

type ErrorStateProps = {
  error: Error | null;
  onReload: () => void;
};

function ErrorState({ error, onReload }: ErrorStateProps) {
  return (
    <motion.div
      className="absolute inset-0 z-20 flex items-center justify-center bg-white p-4"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.2, ease: "linear" }}
    >
      <NoData {...{ error, onReload }} />
    </motion.div>
  );
}
