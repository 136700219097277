import type { MessageUpdateRequest, MessageV2Dto } from "api/types";
import arrowDownIcon from "assets/icons/arrow-narrow-down.svg";
import iconCheck from "assets/icons/check.svg";
import iconCopy01 from "assets/icons/copy-01.svg";
import iconEdit05 from "assets/icons/edit-05.svg";
import iconEyeOff from "assets/icons/eye-off.svg";
import iconFlag01 from "assets/icons/flag-01.svg";
import iconLock01 from "assets/icons/lock-01.svg";
import iconMarkerPin01 from "assets/icons/marker-pin-01.svg";
import iconShare01 from "assets/icons/share-01.svg";
import iconTrash02 from "assets/icons/trash-02.svg";
import iconZap from "assets/icons/zap.svg";
import { Button } from "components/Button/Button";
import { ConfirmModal } from "components/ConfirmModal/ConfirmModal";
import type { FormDocument } from "components/DocumentInput/useDocumentFile";
import { Form } from "components/Form/Form";
import { FormContent } from "components/Form/FormContent";
import { FormDateAndTimePicker } from "components/Form/FormDateAndTimePicker";
import { FormInput } from "components/Form/FormInput";
import { FormTextArea } from "components/Form/FormTextArea";
import { formatDate, FormattedDate } from "components/FormattedDate/FormattedDate";
import { formatDistance } from "components/FormattedDistance/FormattedDistance";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { Icon } from "components/Icon/Icon";
import type { FormImage } from "components/ImageInput/useImageInput";
import { Label } from "components/Label/Label";
import { LinkFormatter } from "components/LinkFormatter/LinkFormatter";
import { Pdf } from "components/Pdf/Pdf";
import { PillButton } from "components/PillButton/PillButton";
import { type FormVideo } from "components/VideoInput/useVideoInput";
import { addMinutes } from "date-fns";
import { createRequiredStringRule } from "helpers/rules";
import {
  isDefined,
  isLongerThanCharLimit,
  isLongerThanWordLimit,
  limitTextByChar,
  limitTextByWord,
} from "helpers/util";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useUploadVideo } from "hooks/Network/useUploadVideo";
import { useBool } from "hooks/useBool";
import { usePermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { useViewportCollision } from "modules/messages/helpers";
import { useCallback, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { routes } from "routes";
import { twJoin } from "tailwind-merge";

import { ArchivePostModal } from "../ArchivePost";
import { CommentButton, LikeButton, LikeCountButton } from "../Buttons";
import { CommentList } from "../CommentList";
import { CommunityItem } from "../CommunityItem";
import { CommunityItemGallery } from "../CommunityItemMedia/CommunityItemGallery";
import { CommunityItemVideo } from "../CommunityItemMedia/CommunityItemVideo";
import { LikeList } from "../LikeList";
import { PromotedGroupCard } from "../PromotedGroupCard";
import { CommentLikesModal } from "./CommentLikesModal";
import { CommunityPostAttachmentField } from "./CommunityPostAttachmentField";
import { useData } from "./helpers";
import { Tabs } from "./helpers";
import { InformAdminModal } from "./InformAdminModal";
import { InformedAdminsModal } from "./InformedAdminsModal";
import { ReportPostModal } from "./ReportPostModal";

const CHAR_LIMIT = 180;
const WORD_LIMIT = 500;

interface ExternalProps {
  message: MessageV2Dto;
  onJoinGroup?: ({ groupId }: { groupId: string }) => Promise<void>;
  isExpanded: boolean;
}

export interface CommunityPostEditFormValues {
  title: string;
  body: string;
  images: FormImage[];
  videos: FormVideo[];
  documents: FormDocument[];
  scheduled?: Date;
}

enum READ_MORE_BTN {
  NONE,
  EXPAND,
  OPEN_DETAILS,
}

export function CommunityPost({ message, isExpanded, onJoinGroup }: ExternalProps): React.ReactNode {
  const response = useData(message, isExpanded);

  return <CommunityPostWithData {...response} isExpanded={isExpanded} onJoinGroup={onJoinGroup} />;
}

function CommunityPostWithData({
  post,
  likes,
  fetchLikes,
  onLike,
  onUnlike,
  onLikeComment,
  onUnlikeComment,
  comments,
  fetchComments,
  isMarkingAsRead,
  commentPost,
  markAsRead,
  deletePost,
  archivePost,
  copyQuickReply,
  editPost,
  editComment,
  deleteComment,
  isExpanded,
  activeTab,
  setActiveTab,
  isSubmitting,
  analyze,
  onAnalyze,
  analysis,
  onCloseAnalyze,
  onTranslate,
  onHideTranslation,
  translation,
  translationIsLoading,
  onGroupFollowChange,
  onJoinGroup,
  showNewCommentPill,
  onFetchNewComments,
}: ReturnType<typeof useData> & {
  isExpanded: boolean;
  onJoinGroup?: ({ groupId }: { groupId: string }) => Promise<void>;
}): React.ReactNode {
  const slug = useSlug();
  const { t, i18n } = useTranslation();
  const hasPermission = usePermission();
  const sessionUser = useSessionUser();
  const refContainer = useRef<HTMLDivElement | null>(null);
  const hasBeenInViewport = useViewportCollision(refContainer);
  const { abortUploadVideos } = useUploadVideo({});

  const [isEditing, editPostHandler] = useBool(false);
  const [isCancelEditModalOpen, cancelEditHandler] = useBool(false);
  const [isDeleteModalOpen, deleteHandler] = useBool(false);
  const [isArchiveModalOpened, archiveModalHandler] = useBool(false);
  const [isInformModalOpened, informModalHandler] = useBool(false);
  const [isInformedAdminsModalOpened, informedAdminsModalHandler] = useBool(false);
  const [isReportModalOpened, reportModalHandler] = useBool(false);
  const [viewLikesState, setViewLikesState] = useState<{ open: boolean; commentId?: string; likeCount?: number }>({
    open: false,
  });
  const [readMoreButton, setReadMoreButton] = useState(() =>
    // eslint-disable-next-line no-nested-ternary
    post?.content
      ? isExpanded || !isLongerThanCharLimit(post.content, CHAR_LIMIT)
        ? READ_MORE_BTN.NONE
        : READ_MORE_BTN.EXPAND
      : READ_MORE_BTN.NONE,
  );

  const form = useForm<CommunityPostEditFormValues>({
    defaultValues: {
      title: post?.title || "",
      body: post?.content || "",
      images: post?.images || [],
      videos: post?.videos || [],
      documents: post?.documents || [],
      scheduled: post?.scheduledAt ? new Date(post.scheduledAt) : undefined,
    },
  });

  const minScheduled = useMemo(() => addMinutes(new Date(), 5), []);

  async function handleEdit() {
    const formValues = form.getValues();

    const payload: MessageUpdateRequest = {
      title: formValues.title,
      content: formValues.body,
      scheduledAt: formValues.scheduled ? formValues.scheduled.toISOString() : undefined,
    };

    await editPost({
      payload: payload,
      images: formValues.images,
      documents: formValues.documents,
      videos: formValues.videos,
      successMessage: t("page.message-feed.edit.notifications.success"),
      failureMessage: t("page.message-feed.edit.notifications.error"),
    });
    editPostHandler.setFalse();
  }

  const handleTabSelection = (selectedTab: Tabs) => {
    switch (selectedTab) {
      case activeTab:
      default:
        setActiveTab(undefined);
        break;
      case Tabs.LIKES:
        setActiveTab(Tabs.LIKES);
        break;
      case Tabs.COMMENTS:
        setActiveTab(Tabs.COMMENTS);
        break;
    }
  };

  const handleLike = async () => {
    if (post?.isLiked) {
      await onUnlike();
    } else {
      await onLike();
    }
  };

  const postContent = useMemo(() => {
    if (!post?.content) {
      return;
    }

    if (readMoreButton === READ_MORE_BTN.EXPAND) {
      return limitTextByChar(post.content, CHAR_LIMIT);
    }

    if (readMoreButton === READ_MORE_BTN.OPEN_DETAILS) {
      return limitTextByWord(post.content, WORD_LIMIT);
    }

    return post.content;
  }, [post?.content, readMoreButton]);

  const handleReadMore = useCallback(() => {
    if (!post?.content) return;

    if (isLongerThanWordLimit(post.content, WORD_LIMIT)) {
      setReadMoreButton(READ_MORE_BTN.OPEN_DETAILS);
    } else {
      setReadMoreButton(READ_MORE_BTN.NONE);
    }
  }, [post]);

  const handleGroupFollowChange = useCallback(
    (id: string, action: "join" | "leave") => {
      void onGroupFollowChange();
      if (action === "join") {
        void onJoinGroup?.({ groupId: id });
      }
    },
    [onGroupFollowChange, onJoinGroup],
  );

  const onClickTranslate = () => {
    if (translation) onHideTranslation();
    else onTranslate();
  };

  const onCancelEdit = () => {
    abortUploadVideos(form.getValues("videos"));

    form.reset();
    editPostHandler.setFalse();
    cancelEditHandler.setFalse();
  };

  const actions = useMemo(() => {
    const actions = [];

    if (!post || post?.deletedAt) return [];

    if (hasPermission((x) => x.isSuperAdmin)) {
      actions.push({
        dataTestId: "post-context-menu-inform-admin",
        text: "Analyze 🤖",
        icon: <Icon name={iconZap} size={16} />,
        callback: onAnalyze,
      });

      actions.push({
        dataTestId: "post-context-menu-copy",
        text: t("component.community-post.actions.quick-reply-link"),
        icon: <Icon name={iconCopy01} size={16} />,
        callback: copyQuickReply,
      });
    }

    if (post.isUnread) {
      actions.push({
        dataTestId: "post-context-menu-mark-read",
        text: t("component.community-post.actions.mark-as-read"),
        icon: <Icon name={iconCheck} size={16} />,
        callback: markAsRead,
      });
    }

    if (post.canEdit) {
      actions.push({
        dataTestId: "context-menu-edit-btn",
        text: t("component.community-post.actions.edit"),
        icon: <Icon name={iconEdit05} size={16} />,
        callback: editPostHandler.toggle,
      });
    }

    if (post.canShare) {
      actions.push({
        dataTestId: "post-context-menu-inform",
        text: t("component.community-post.actions.inform"),
        icon: <Icon name={iconShare01} size={16} />,
        callback: informModalHandler.toggle,
      });
    }

    if (post.canDelete) {
      if (sessionUser.isAdmin || !post.canArchive) {
        actions.push({
          dataTestId: "context-menu-delete-btn",
          text: t("component.community-post.actions.delete"),
          icon: <Icon name={iconTrash02} size={16} />,
          callback: deleteHandler.toggle,
        });
      } else {
        actions.push({
          dataTestId: "post-context-menu-archive",
          text: t("component.community-post.actions.close"),
          icon: <Icon name={iconCheck} size={16} />,
          callback: archiveModalHandler.toggle,
        });
      }
    }

    if (post.canReport) {
      actions.push({
        dataTestId: "post-context-menu-report",
        text: t("component.community-post.actions.report"),
        icon: <Icon name={iconFlag01} size={16} />,
        callback: reportModalHandler.toggle,
      });
    }

    return actions;
  }, [
    post,
    sessionUser,
    hasPermission,
    onAnalyze,
    t,
    copyQuickReply,
    markAsRead,
    editPostHandler.toggle,
    informModalHandler.toggle,
    deleteHandler.toggle,
    archiveModalHandler.toggle,
    reportModalHandler.toggle,
  ]);

  if (!post) {
    return null;
  }

  const content = translation?.content || postContent;

  return (
    <>
      <CommunityItem
        ring={
          // eslint-disable-next-line no-nested-ternary
          !!post.deletedAt || !!post.archivedAt
            ? "greyed-out"
            : // eslint-disable-next-line no-nested-ternary
              post.isUnread
              ? "unread"
              : post.reportedAt
                ? "reported"
                : undefined
        }
        user={post.author}
        actions={actions}
        group={post.group}
        labels={[
          post.projectConnection ? (
            <Label theme="aopDarkBlue">
              <Icon name={iconMarkerPin01} size={16} />
              {post.projectConnection.name}
            </Label>
          ) : undefined,
          post.reportedAt && !post.deletedAt ? (
            <Label data-testid="flagged-label" theme="red">
              {t("component.community-post.flagged")}
            </Label>
          ) : undefined,
          post.hasAdminBeenInformed && !post.deletedAt ? (
            <Label data-testid="post-informed-label" theme="green" onClick={() => informedAdminsModalHandler.toggle()}>
              {t("component.community-post.shared")}
            </Label>
          ) : undefined,
          post.type === "announcement" || post.type === "announcementReadOnly" ? (
            <Label theme="blue">{t("component.community-post.announcement")}</Label>
          ) : undefined,
          post.deletedAt ? <Label theme="red">{t("component.community-post.removed")}</Label> : undefined,
        ].filter(isDefined)}
        dateSubtitle={
          // eslint-disable-next-line no-nested-ternary
          // eslint-disable-next-line no-nested-ternary
          post.scheduledAt ? (
            <span className="text-red-600" data-testid="post-scheduled-date">
              {t("component.community-post.scheduled-for", {
                scheduled: formatDate(i18n, "datetime", post.scheduledAt),
              })}
            </span> // eslint-disable-next-line no-nested-ternary
          ) : post.postedAt ? (
            // eslint-disable-next-line no-nested-ternary
            post.archivedAt ? (
              <p>
                <FormattedDate format="datetime" date={post.postedAt} /> •{" "}
                <span className="text-caption-bold uppercase">{t("component.community-post.archived")}</span>
              </p>
            ) : post.updatedAt ? (
              <p>
                <FormattedDate format="datetime" date={post.postedAt} /> •{" "}
                <span className="text-caption-bold uppercase">{t("component.community-post.edited")}</span>
              </p>
            ) : (
              <FormattedDate format="datetime" date={post.postedAt} />
            )
          ) : undefined
        }
        onClick={!isMarkingAsRead && post.isUnread ? markAsRead : undefined}
        warningHeaders={[
          post.isHiddenFromAdmins
            ? {
                icon: <Icon name={iconEyeOff} size={16} />,
                text: t("component.community-post.hidden-from-property-manager"),
              }
            : undefined,
          !post.canComment && sessionUser.role.type !== "readOnly"
            ? { icon: <Icon name={iconLock01} size={16} />, text: t("component.community-post.no-comment-allowed") }
            : undefined,
        ].filter(isDefined)}
        ref={refContainer}
      >
        <div className="flex w-full flex-col gap-2 md:flex-row">
          <div className="flex w-full flex-1 flex-col gap-2">
            {
              // eslint-disable-next-line no-nested-ternary
              !!post.deletedAt && !sessionUser.isSuperAdmin ? (
                <p className="whitespace-pre-line text-grey-500">{t("component.community-post.content.removed")}</p>
              ) : isEditing ? (
                <Form formMethods={form} onSubmit={handleEdit}>
                  <FormContent>
                    <FormInput<CommunityPostEditFormValues>
                      name="title"
                      placeholder={t("page.message-feed.create.title.placeholder")}
                      rules={{
                        validate: {
                          required: createRequiredStringRule(t, "page.message-feed.create.title"),
                        },
                      }}
                    />
                    <FormTextArea<CommunityPostEditFormValues>
                      className="max-h-[300px]"
                      name="body"
                      placeholder={t("page.message-feed.create.body.placeholder")}
                      rules={{
                        validate: {
                          required: createRequiredStringRule(t, "page.message-feed.create.body"),
                        },
                      }}
                    />
                    {post.relatedGroup ? (
                      <PromotedGroupCard
                        id={post.relatedGroup.id}
                        name={post.relatedGroup.name}
                        description={post.relatedGroup.description}
                        image={post.relatedGroup.image}
                        isReadonly
                        isResidentGroup={post.relatedGroup.isResidentGroup}
                      />
                    ) : (
                      <CommunityPostAttachmentField />
                    )}
                    {post.scheduledAt ? (
                      <FormDateAndTimePicker<CommunityPostEditFormValues>
                        name="scheduled"
                        type="datetime"
                        min={minScheduled}
                        rules={{
                          validate: {
                            laterThanMin: (date) => {
                              if (date == null) {
                                return undefined;
                              }

                              return date < minScheduled
                                ? t("page.message-feed.create.schedule.datetime.error.must-be-in-future")
                                : undefined;
                            },
                          },
                          required: t("components.form.error.required", {
                            inputName: t("page.message-feed.create.schedule.datetime"),
                          }),
                        }}
                      />
                    ) : null}
                  </FormContent>
                  <div className="mt-4 flex justify-end gap-2">
                    <Button styling="secondary" onClick={cancelEditHandler.setTrue}>
                      {t("common.action.cancel")}
                    </Button>
                    <Button styling="primary" type="submit" isLoading={isSubmitting}>
                      {t("common.action.save")}
                    </Button>
                  </div>
                </Form>
              ) : (
                // eslint-disable-next-line no-nested-ternary
                <>
                  <p
                    className={twJoin(
                      "whitespace-pre-line font-old-semibold",
                      post.deletedAt && "text-grey-500 line-through",
                    )}
                    data-testid="post-title"
                  >
                    {translation?.title || post.title}
                  </p>
                  {content && (
                    <p
                      className={post.deletedAt ? "text-grey-500 line-through" : "text-grey-900"}
                      data-testid="post-content"
                    >
                      <LinkFormatter>{content}</LinkFormatter>
                    </p>
                  )}
                  <div className="flex flex-col gap-2">
                    {
                      // eslint-disable-next-line no-nested-ternary
                      translation ? null : readMoreButton === READ_MORE_BTN.EXPAND ? (
                        <Button styling="ghostPrimary" onClick={handleReadMore}>
                          {t("component.community-post.content.read-more")}
                        </Button>
                      ) : (
                        readMoreButton === READ_MORE_BTN.OPEN_DETAILS && (
                          <Button
                            type="link"
                            styling="ghostPrimary"
                            href={routes.messageFeed.details({ slug, id: post.id })}
                          >
                            {t("component.community-post.content.read-more")}
                          </Button>
                        )
                      )
                    }
                    {!isEditing && post.languageIsoCode !== sessionUser.language.id && (
                      <Button
                        styling="ghostPrimary"
                        data-testid="translate-btn"
                        isLoading={translationIsLoading}
                        onClick={onClickTranslate}
                      >
                        {translation
                          ? t("component.community-post.content.translate.original")
                          : t("component.community-post.content.translate")}
                      </Button>
                    )}
                  </div>
                  {post.relatedGroup && (
                    <PromotedGroupCard
                      id={post.relatedGroup.id}
                      name={post.relatedGroup.name}
                      description={post.relatedGroup.description}
                      image={post.relatedGroup.image}
                      isResidentGroup={post.relatedGroup.isResidentGroup}
                      isOwner={post.relatedGroup.isOwner}
                      isMember={post.relatedGroup.isMember}
                      followCallback={handleGroupFollowChange}
                    />
                  )}
                  {post.images.length > 0 && (
                    <div className="w-full lg:w-2/3" data-testid="attachment-image">
                      <CommunityItemGallery images={post.images} shouldLoad={hasBeenInViewport} />
                    </div>
                  )}
                  {post.videos && post.videos.length > 0 && (
                    <div className="w-full lg:w-2/3" data-testid="attachment-video">
                      <CommunityItemVideo video={post.videos[0]} shouldLoad={hasBeenInViewport} />
                    </div>
                  )}
                  {post.documents && post.documents.length > 0 && (
                    <div className="w-full lg:w-2/3" data-testid="attachment-document">
                      <Pdf
                        key={post.documents[0].id}
                        previewImage={post.documents[0].previewImage}
                        fileName={post.documents[0].fileName}
                        fileSize={post.documents[0].fileSize}
                        onClick={() => window.open(post.documents![0].url, "_blank")}
                      />
                    </div>
                  )}
                </>
              )
            }
          </div>
        </div>
        {!post.scheduledAt && (
          <div
            className={twJoin(
              "-my-1 flex flex-col items-center justify-between gap-4 @xl:flex-row",
              activeTab !== undefined && "border-b border-b-grey-300 pb-2",
            )}
          >
            <div className="flex items-center gap-2">
              {!post.archivedAt && !post.deletedAt && <LikeButton isActive={post.isLiked} onClick={handleLike} />}
              <LikeCountButton
                total={post.totalLikeCount}
                isActive={activeTab === Tabs.LIKES}
                isGreyedOut={!!post.archivedAt || !!post.deletedAt}
                onClick={() => {
                  if (post.totalLikeCount > 0) {
                    handleTabSelection(Tabs.LIKES);
                  }
                }}
              />
              <CommentButton
                isActive={activeTab === Tabs.COMMENTS}
                isGreyedOut={!!post.archivedAt || !!post.deletedAt}
                total={post.totalCommentCount}
                onClick={() => {
                  if (post.canComment || post.totalCommentCount > 0) {
                    handleTabSelection(Tabs.COMMENTS);
                  }
                }}
              />
            </div>
            {post.lastActivityAt && (
              <span className="order-first text-caption text-grey-500 @xl:order-last">
                {t("component.community-post.last-activity", {
                  time: formatDistance(i18n, { start: new Date(post.lastActivityAt) }),
                })}
              </span>
            )}
          </div>
        )}
        {activeTab === Tabs.LIKES && likes && (
          <LikeList likes={likes} hasMoreLikes={!!fetchLikes.hasNextPage} onLoadMore={fetchLikes.fetchNextPage} />
        )}
        {activeTab === Tabs.COMMENTS && comments && (
          <div className="relative">
            <CommentList
              messageId={post.id}
              comments={comments}
              isLoadingComments={fetchComments.isFetching}
              hasMoreComments={!!fetchComments.hasNextPage}
              onLoadMore={fetchComments.fetchNextPage}
              commentPost={commentPost}
              editComment={editComment}
              deleteComment={deleteComment}
              onLikeComment={onLikeComment}
              onUnlikeComment={onUnlikeComment}
              onViewCommentLikes={(x) =>
                setViewLikesState({ open: true, commentId: x.commentId, likeCount: x.likeCount })
              }
              isGreyedOut={!post.canComment}
            />
            {showNewCommentPill ? (
              <PillButton className="absolute bottom-12 left-1/2" onClick={onFetchNewComments}>
                <Icon name={arrowDownIcon} size={16} />
                <span className="text-caption-bold">{t("component.community-post.comments.load-new")}</span>
              </PillButton>
            ) : null}
          </div>
        )}
      </CommunityItem>
      <ConfirmModal
        title={t("component.community-post.cancel-edit.modal.title")}
        description={t("component.community-post.cancel-edit.modal.description")}
        isLoading={false}
        onReject={cancelEditHandler.setFalse}
        onOpenChange={cancelEditHandler.set}
        rejectBtnProps={{
          "data-testid": "cancel-edit-modal-cancel",
        }}
        onResolve={onCancelEdit}
        resolveBtnProps={{
          text: t("common.action.delete"),
          "data-testid": "cancel-edit-modal-confirm",
        }}
        isOpened={isCancelEditModalOpen}
        shouldCloseOnEsc
        data-testid="cancel-edit-modal"
      />
      <ConfirmModal
        title={t("component.community-post.delete.modal.title")}
        description={t("component.community-post.delete.modal.description")}
        isLoading={false}
        theme="danger"
        onReject={deleteHandler.setFalse}
        onOpenChange={deleteHandler.set}
        rejectBtnProps={{
          "data-testid": "delete-modal-cancel",
        }}
        onResolve={() => {
          void deletePost({ reason: "undefined" });
          deleteHandler.setFalse();
        }}
        resolveBtnProps={{
          "data-testid": "modal-confirm-delete",
          text: t("common.action.delete"),
        }}
        isOpened={isDeleteModalOpen}
        shouldCloseOnEsc
        data-testid="cancel-edit-modal"
      />
      <InformAdminModal isOpened={isInformModalOpened} onOpenChange={informModalHandler.set} messageId={post.id} />
      <InformedAdminsModal
        isOpened={isInformedAdminsModalOpened}
        onOpenChange={informedAdminsModalHandler.set}
        messageId={post.id}
      />
      <ReportPostModal isOpened={isReportModalOpened} onOpenChange={reportModalHandler.set} messageId={post.id} />
      <CommentLikesModal
        isOpened={viewLikesState.open}
        messageId={post.id}
        onOpenChange={(state) => {
          if (!state) {
            setViewLikesState(() => ({ open: false }));
          } else {
            setViewLikesState((x) => ({ ...x, open: state }));
          }
        }}
        commentId={viewLikesState.commentId}
        initialLikes={viewLikesState.likeCount}
      />
      <ConfirmModal
        isOpened={analyze}
        title="Analysis"
        description="An analysis of how good your post is and what topics it covers."
        isLoading={!analysis}
        onResolve={() => onCloseAnalyze()}
        onReject={() => onCloseAnalyze()}
        onOpenChange={(state) => {
          if (!state) {
            onCloseAnalyze();
          }
        }}
      >
        {analysis?.topics && analysis?.sentiment ? (
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2">
              <span className="text-body-bold">Topics:</span>
              <span>{analysis.topics?.topics?.length > 1 ? analysis.topics?.topics.join(", ") : "-"}</span>
            </div>
            <div className="flex items-center gap-2">
              <span className="text-body-bold">Sentiment:</span>
              <span className="text-headline3">
                {analysis.sentiment?.score || "-"}
                <span className="text-body text-grey-600">/{analysis.sentiment?.outOf || "10"}</span>
              </span>
            </div>
          </div>
        ) : (
          <div className="px-10 py-5">
            <FullSizeLoader />
          </div>
        )}
      </ConfirmModal>
      <ArchivePostModal
        isOpened={isArchiveModalOpened}
        onOpenChange={archiveModalHandler.set}
        onDelete={deletePost}
        onArchive={archivePost}
      />
    </>
  );
}
