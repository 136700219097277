import type {
  CommunityGroupV2Dto,
  DocumentsFolderDto,
  DocumentsRootFolderDto,
  ServicePartnerPageDto,
  TicketCategoryDto,
  UserRoleAddressManagementPermissionsRequest,
  UserRoleAssetsPermissionsRequest,
  UserRoleCommunityGroupPermissionsEntityRequest,
  UserRoleCommunityPermissionsRequest,
  UserRoleDetailsDto,
  UserRoleDocumentFolderPermissionsEntityRequest,
  UserRoleEventsPermissionsRequest,
  UserRoleProjectManagementPermissionsRequest,
  UserRoleRepairCategoryPermissionsEntityRequest,
  UserRoleRepairManagementPermissionsRequest,
  UserRoleServicePagesPermissionsRequest,
  UserRoleServicePartnerPagePermissionsEntityRequest,
  UserRoleSurveysPermissionsRequest,
  UserRoleUserManagementPermissionsRequest,
} from "api/types";
import type { TFunction } from "i18next";

import type { RoleFormFieldValues } from "./Layout";
import type { PermissionLayoutStructure } from "./Loader";

const roleWizardStep = { step1: 1, step2: 2 } as const;
export type RoleWizardStep = (typeof roleWizardStep)[keyof typeof roleWizardStep];

export function compileStructure(
  t: TFunction,
  communityGroups: CommunityGroupV2Dto[],
  ticketCategories: TicketCategoryDto[],
  servicePartners: ServicePartnerPageDto[],
  folders: DocumentsRootFolderDto[],
): PermissionLayoutStructure[] {
  const communityGroupsStructure = communityGroups.map((group) => {
    const isInterest = group.type === "interest";

    return {
      id: group.id,
      groupLabel: group.name,
      items: [
        {
          label: isInterest
            ? t(`model.permission.community.interest-group.can-create-post`)
            : t(`model.permission.community.help-category.can-create-post`),
          modelPath: `permissions.communityGroups.${group.id}.canCreatePost`,
        },
        {
          label: isInterest
            ? t(`model.permission.community.interest-group.can-create-announcement`)
            : t(`model.permission.community.help-category.can-create-announcement`),
          modelPath: `permissions.communityGroups.${group.id}.canCreateAnnouncement`,
        },
        {
          label: isInterest
            ? t(`model.permission.community.interest-group.can-list-posts`)
            : t(`model.permission.community.help-category.can-list-posts`),
          modelPath: `permissions.communityGroups.${group.id}.canListPosts`,
        },
        {
          label: isInterest
            ? t(`model.permission.community.interest-group.can-comment`)
            : t(`model.permission.community.help-category.can-comment`),
          modelPath: `permissions.communityGroups.${group.id}.canCommentPost`,
        },
      ],
    } satisfies PermissionLayoutStructure;
  });
  const communityStructure = {
    id: t("model.permission.community"),
    groupLabel: t("model.permission.community"),
    items: [
      {
        label: t("model.permission.community.can-list-groups"),
        modelPath: "permissions.community.canListGroups",
      },
      {
        label: t("model.permission.community.can-create-group"),
        modelPath: "permissions.community.canCreateGroup",
      },
      {
        label: t("model.permission.community.can-edit-group"),
        modelPath: "permissions.community.canEditGroup",
      },
      {
        label: t("model.permission.community.can-delete-group"),
        modelPath: "permissions.community.canDeleteGroup",
      },
      {
        label: t("model.permission.community.can-create-general-post"),
        modelPath: "permissions.community.canCreateGeneralPost",
      },
      {
        label: t("model.permission.community.can-create-general-post-project-connection"),
        modelPath: "permissions.community.canCreateGeneralPostInProjectConnection",
        tooltip: t("model.permission.community.can-create-general-post-project-connection.tooltip"),
      },
      {
        label: t("model.permission.community.can-create-general-announcement"),
        modelPath: "permissions.community.canCreateGeneralAnnouncement",
      },
      {
        label: t("model.permission.community.can-list-general-posts"),
        modelPath: "permissions.community.canListGeneralPosts",
      },
      {
        label: t("model.permission.community.can-comment-general-posts"),
        modelPath: "permissions.community.canCommentGeneralPosts",
      },
    ],
    subStructures: communityGroupsStructure,
  } satisfies PermissionLayoutStructure;

  const repairCategoriesStructure = ticketCategories.map((category) => {
    return {
      id: category.id,
      groupLabel: category.name,
      items: [
        {
          label: t("model.permission.repair-category.can-list-all"),
          modelPath: `permissions.repairCategories.${category.id}.canListAllRequests`,
        },
        {
          label: t("model.permission.repair-category.can-add-request"),
          modelPath: `permissions.repairCategories.${category.id}.canAddRequest`,
        },
        {
          label: t("model.permission.repair-category.can-update-status"),
          modelPath: `permissions.repairCategories.${category.id}.canUpdateRequestStatus`,
        },
        {
          label: t("model.permission.repair-category.can-be-assigned"),
          modelPath: `permissions.repairCategories.${category.id}.canBeAssigned`,
        },
        {
          label: t("model.permission.repair-category.can-comment-public"),
          modelPath: `permissions.repairCategories.${category.id}.canCommentPublic`,
        },
        {
          label: t("model.permission.repair-category.can-comment-internal"),
          modelPath: `permissions.repairCategories.${category.id}.canCommentInternal`,
        },
        {
          label: t("model.permission.repair-category.can-list-collective"),
          modelPath: `permissions.repairCategories.${category.id}.canListCollectiveRequests`,
        },
        {
          label: t("model.permission.repair-category.can-list-assigned-to-me"),
          modelPath: `permissions.repairCategories.${category.id}.canListAssignedToMeRequests`,
        },
      ],
    } satisfies PermissionLayoutStructure;
  });

  const repairRequestStructure = {
    id: t("model.permission.repair-request"),
    groupLabel: t("model.permission.repair-request"),
    items: [
      {
        label: t("model.permission.repair-request.can-manage-categories"),
        modelPath: "permissions.repairManagement.canManageCategories",
      },
      {
        label: t("model.permission.repair-request.can-list-categories"),
        modelPath: "permissions.repairManagement.canListCategories",
      },
      {
        label: t("model.permission.repair-request.can-manage-statuses"),
        modelPath: "permissions.repairManagement.canManageStatuses",
      },
      {
        label: t("model.permission.repair-request.can-list-statuses"),
        modelPath: "permissions.repairManagement.canListStatuses",
      },
    ],
    subStructures: repairCategoriesStructure,
  } satisfies PermissionLayoutStructure;

  const userManagementStructure = {
    id: t("model.permission.user-management"),
    groupLabel: t("model.permission.user-management"),
    items: [
      {
        label: t("model.permission.user-management.can-manage-roles"),
        modelPath: "permissions.userManagement.canManageRoles",
      },
      {
        label: t("model.permission.user-management.can-assign-roles"),
        modelPath: "permissions.userManagement.canAssignRoles",
      },
      {
        label: t("model.permission.user-management.can-invite-user"),
        modelPath: "permissions.userManagement.canInviteUser",
      },
      {
        label: t("model.permission.user-management.can-delete-user"),
        modelPath: "permissions.userManagement.canDeleteUser",
      },
      {
        label: t("model.permission.user-management.can-edit-resident-info"),
        modelPath: "permissions.userManagement.canEditResidentInfo",
      },
      {
        label: t("model.permission.user-management.can-edit-admin-info"),
        modelPath: "permissions.userManagement.canEditAdminInfo",
      },
      {
        label: t("model.permission.user-management.can-list-users"),
        modelPath: "permissions.userManagement.canListUsers",
      },
    ],
  } satisfies PermissionLayoutStructure;

  const addressManagementStructure = {
    id: t("model.permission.address-management"),
    groupLabel: t("model.permission.address-management"),
    items: [
      {
        label: t("model.permission.address-management.can-manage-buildings"),
        modelPath: "permissions.addressManagement.canManageBuildings",
      },
      {
        label: t("model.permission.address-management.can-manage-companies"),
        modelPath: "permissions.addressManagement.canManageCompanies",
      },
      {
        label: t("model.permission.address-management.can-list-addresses"),
        modelPath: "permissions.addressManagement.canListAddresses",
      },
      {
        label: t("model.permission.address-management.can-create-address"),
        modelPath: "permissions.addressManagement.canManageAddresses",
      },
      {
        label: t("model.permission.address-management.can-edit-address"),
        modelPath: "permissions.addressManagement.canEditAddress",
      },
    ],
  } satisfies PermissionLayoutStructure;

  const servicePagesStructure = servicePartners.map((page) => {
    return {
      id: page.id,
      groupLabel: page.title,
      items: [
        {
          label: t("model.permission.service-page.can-manage-content"),
          modelPath: `permissions.servicePages.${page.id}.canManageContent`,
        },
      ],
    } satisfies PermissionLayoutStructure;
  });

  const servicePartnersStructure = {
    id: t("model.permission.service-partner"),
    groupLabel: t("model.permission.service-partner"),
    items: [
      {
        label: t("model.permission.service-partner.can-manage-pages"),
        modelPath: "permissions.servicePartners.canManagePages",
      },
      {
        label: t("model.permission.service-partner.can-create-pages"),
        modelPath: "permissions.servicePartners.canCreatePages",
      },
      {
        label: t("model.permission.service-partner.can-list-pages"),
        modelPath: "permissions.servicePartners.canListPages",
      },
    ],
    subStructures: servicePagesStructure,
  } satisfies PermissionLayoutStructure;

  const eventsStructure = {
    id: t("model.permission.event"),
    groupLabel: t("model.permission.event"),
    items: [
      {
        label: t("model.permission.event.can-manage"),
        modelPath: "permissions.events.canManage",
      },
      {
        label: t("model.permission.event.can-list-all"),
        modelPath: "permissions.events.canListAll",
      },
    ],
  } satisfies PermissionLayoutStructure;

  const bookableAssetsStructure = {
    id: t("model.permission.bookable-asset"),
    groupLabel: t("model.permission.bookable-asset"),
    items: [
      {
        label: t("model.permission.bookable-asset.can-view-schedule"),
        modelPath: "permissions.assets.canViewSchedule",
      },
      {
        label: t("model.permission.bookable-asset.can-manage-own"),
        modelPath: "permissions.assets.canManageOwn",
      },
      {
        label: t("model.permission.bookable-asset.can-manage-all"),
        modelPath: "permissions.assets.canManageAll",
      },
    ],
  } satisfies PermissionLayoutStructure;

  const folderStructure = {
    id: t("model.permission.folder"),
    groupLabel: t("model.permission.folder"),
    subStructures: folders.map((folder) => ({
      id: folder.id,
      groupLabel: folder.name,
      items: [
        {
          label: t(`model.permission.folder.can-list-documents`),
          modelPath: `permissions.folders.${folder.id}.canListDocuments`,
        },
        {
          label: t(`model.permission.folder.can-create-document`),
          modelPath: `permissions.folders.${folder.id}.canCreateDocument`,
        },
        {
          label: t(`model.permission.folder.can-delete-document`),
          modelPath: `permissions.folders.${folder.id}.canDeleteDocument`,
        },
      ],
    })),
  } satisfies PermissionLayoutStructure;

  const surveysStructure = {
    id: t("model.permission.survey"),
    groupLabel: t("model.permission.survey"),
    items: [
      {
        label: t("model.permission.survey.can-list-all"),
        modelPath: "permissions.surveys.canListAll",
      },
      {
        label: t("model.permission.survey.can-manage-own"),
        modelPath: "permissions.surveys.canManage",
      },
    ],
  } satisfies PermissionLayoutStructure;

  const projectManagementStructure = {
    id: t("model.permission.project-management"),
    groupLabel: t("model.permission.project-management"),
    items: [
      {
        label: t("model.permission.project-management.can-view-all"),
        modelPath: "permissions.projectManagement.canViewAll",
      },
    ],
  } satisfies PermissionLayoutStructure;

  return [
    communityStructure,
    repairRequestStructure,
    userManagementStructure,
    addressManagementStructure,
    servicePartnersStructure,
    eventsStructure,
    bookableAssetsStructure,
    folderStructure,
    surveysStructure,
    projectManagementStructure,
  ];
}

export function getDefaultFormValues(
  data: {
    communityGroups: CommunityGroupV2Dto[];
    ticketCategories: TicketCategoryDto[];
    servicePartners: ServicePartnerPageDto[];
    folders: DocumentsFolderDto[];
  },
  roleDetails: UserRoleDetailsDto | undefined,
): RoleFormFieldValues {
  return {
    name: roleDetails?.name || "",
    type: roleDetails?.type || "resident",
    permissions: {
      community: mergeSimilarObjects<UserRoleCommunityPermissionsRequest>(
        {
          canListGroups: false,
          canCreateGroup: false,
          canEditGroup: false,
          canDeleteGroup: false,
          canCreateGeneralPost: false,
          canCreateGeneralAnnouncement: false,
          canListGeneralPosts: false,
          canCommentGeneralPosts: false,
          canCreateGeneralPostInProjectConnection: false,
        },
        roleDetails?.permissions.community,
      ),
      communityGroups: mergeObjectArraysBy(
        data.communityGroups.map(
          (group) =>
            ({
              groupId: group.id,
              canCreatePost: false,
              canCreateAnnouncement: false,
              canListPosts: false,
              canCommentPost: false,
            }) as UserRoleCommunityGroupPermissionsEntityRequest,
        ),
        roleDetails?.permissions.communityGroups,
        (x) => x.groupId,
      ),
      repairManagement: mergeSimilarObjects<UserRoleRepairManagementPermissionsRequest>(
        {
          canManageCategories: false,
          canListCategories: false,
          canManageStatuses: false,
          canListStatuses: false,
        },
        roleDetails?.permissions.repairManagement,
      ),
      repairCategories: mergeObjectArraysBy(
        data.ticketCategories.map(
          (repairCategory) =>
            ({
              categoryId: repairCategory.id,
              canListAllRequests: false,
              canAddRequest: false,
              canUpdateRequestStatus: false,
              canBeAssigned: false,
              canCommentPublic: false,
              canCommentInternal: false,
              canListCollectiveRequests: false,
              canListAssignedToMeRequests: false,
            }) as UserRoleRepairCategoryPermissionsEntityRequest,
        ),
        roleDetails?.permissions.repairCategories,
        (x) => x.categoryId,
      ),
      folders: mergeObjectArraysBy(
        data.folders.map(
          (folder) =>
            ({
              folderId: folder.id,
              canCreateDocument: false,
              canDeleteDocument: false,
              canListDocuments: false,
            }) as UserRoleDocumentFolderPermissionsEntityRequest,
        ),
        roleDetails?.permissions.folders,
        (x) => x.folderId,
      ),
      userManagement: mergeSimilarObjects<UserRoleUserManagementPermissionsRequest>(
        {
          canManageRoles: false,
          canAssignRoles: false,
          canInviteUser: false,
          canDeleteUser: false,
          canEditResidentInfo: false,
          canEditAdminInfo: false,
          canListUsers: false,
        },
        roleDetails?.permissions.userManagement,
      ),
      addressManagement: mergeSimilarObjects<UserRoleAddressManagementPermissionsRequest>(
        {
          canManageCompanies: false,
          canListAddresses: false,
          canManageAddresses: false,
          canEditAddress: false,
          canManageBuildings: false,
        },
        roleDetails?.permissions.addressManagement,
      ),
      servicePartners: mergeSimilarObjects<UserRoleServicePagesPermissionsRequest>(
        {
          canManagePages: false,
          canListPages: false,
          canCreatePages: false,
        },
        roleDetails?.permissions.servicePartners,
      ),
      servicePages: mergeObjectArraysBy(
        data.servicePartners.map(
          (servicePage) =>
            ({
              pageId: servicePage.id,
              canManageContent: false,
            }) as UserRoleServicePartnerPagePermissionsEntityRequest,
        ),
        roleDetails?.permissions.servicePages,
        (x) => x.pageId,
      ),
      surveys: mergeSimilarObjects<UserRoleSurveysPermissionsRequest>(
        {
          canListAll: false,
          canManage: false,
        },
        roleDetails?.permissions.surveys,
      ),
      events: mergeSimilarObjects<UserRoleEventsPermissionsRequest>(
        {
          canManage: false,
          canListAll: false,
        },
        roleDetails?.permissions.events,
      ),
      assets: mergeSimilarObjects<UserRoleAssetsPermissionsRequest>(
        {
          canViewSchedule: false,
          canManageOwn: false,
          canManageAll: false,
        },
        roleDetails?.permissions.assets,
      ),
      projectManagement: mergeSimilarObjects<UserRoleProjectManagementPermissionsRequest>(
        {
          canViewAll: false,
        },
        roleDetails?.permissions.projectManagement,
      ),
    },
  };
}

function mergeSimilarObjects<T>(baseObject: T, object: T | undefined) {
  return {
    ...baseObject,
    ...object,
  };
}

function mergeObjectArraysBy<T extends {}>(
  baseObject: T[],
  object: T[] | undefined,
  keySelector: (value: T) => string,
) {
  const record: Record<string, T> = {};

  for (const value of baseObject) {
    const id = keySelector(value);
    record[id] = value;
  }

  if (object) {
    for (const value of object) {
      const id = keySelector(value);
      record[id] = {
        ...record[id],
        ...value,
      };
    }
  }

  return record;
}
